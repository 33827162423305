// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@layer base {
    ul {
        list-style-type: disc;
        list-style-position: outside;
        margin-left: 25px;
    }

    ol {
        list-style-type: decimal;
        list-style-position: outside;
        margin-left: 25px;
    }
}

body, html {
    font-family: 'Inter', sans-serif;
    background-color: white;
    margin: 0px;
    padding: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;IACI;QACI,qBAAqB;QACrB,4BAA4B;QAC5B,iBAAiB;IACrB;;IAEA;QACI,wBAAwB;QACxB,4BAA4B;QAC5B,iBAAiB;IACrB;AACJ;;AAEA;IACI,gCAAgC;IAChC,uBAAuB;IACvB,WAAW;IACX,YAAY;AAChB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap');\n\n@layer base {\n    ul {\n        list-style-type: disc;\n        list-style-position: outside;\n        margin-left: 25px;\n    }\n\n    ol {\n        list-style-type: decimal;\n        list-style-position: outside;\n        margin-left: 25px;\n    }\n}\n\nbody, html {\n    font-family: 'Inter', sans-serif;\n    background-color: white;\n    margin: 0px;\n    padding: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
